import { faAngleLeft, faAngleRight } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core'
import { Link } from 'gatsby'
import React from 'react'

export interface BlogNav {
  fields: { slug: string }
  frontmatter: { title: string }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navButton: {
      fontSize: theme.typography.subtitle2.fontSize,
      width: '100%',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    },
    navButtonLabel: {
      position: 'relative',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
    },
    navContentPrev: {
      marginLeft: '10%',
    },
    navContentNext: {
      marginRight: '10%',
    },
    navIcon: {
      fontSize: theme.typography.h4.fontSize,
      position: 'absolute',
      top: '50%',
      width: '10% !important',
      height: '100%',
      transform: 'translate(-25%,-50%)',
      zIndex: 0,
    },
    navIconPrev: {
      left: -5,
    },
    navIconNext: {
      right: -15,
    },
  })
)

export default function BlogNavButton(props: { type: 'prev' | 'next'; target: BlogNav }) {
  const classes = useStyles()
  const { type, target } = props
  const isPrev = type === 'prev'
  return (
    <Grid item sm={5} xs={6}>
      <Button
        variant="outlined"
        color="primary"
        component={Link}
        to={target.fields.slug}
        classes={{ root: classes.navButton, label: classes.navButtonLabel }}
      >
        <FontAwesomeIcon
          icon={isPrev ? faAngleLeft : faAngleRight}
          className={[classes.navIcon, isPrev ? classes.navIconPrev : classes.navIconNext].join(
            ' '
          )}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isPrev ? 'flex-end' : 'flex-start'}
          className={isPrev ? classes.navContentPrev : classes.navContentNext}
        >
          <Box component="span" fontSize="caption.fontSize" flex="0 0 auto">
            {isPrev ? '上一篇' : '下一篇'}
          </Box>
          <Box flex="1 1 auto" display="flex" alignItems="center">
            {target.frontmatter.title}
          </Box>
        </Box>
      </Button>
    </Grid>
  )
}
