import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { Styles } from '@material-ui/styles'
import defaults from 'lodash/defaults'
import merge from 'lodash/merge'

export interface AnimatedPageProps {
  progress: number
}

export interface PageArticle {
  mdx: {
    frontmatter: { title: string }
    body: string
  }
}

export const articleMask =
  'linear-gradient(to bottom, transparent, black 10%, black 90%,transparent)'

export const useIntroStyles = <ClassKey extends string = string>(options?: {
  titleColor?: string
  articleColor?: string
  buttonColor?: string
  extra?: Styles<Theme, {}, ClassKey>
}) => {
  const finalOptions = defaults({}, options, {
    titleColor: 'white',
    articleColor: 'white',
    buttonColor: 'white',
  })
  return makeStyles((theme: Theme) => {
    let extraStyles: any = {}
    if (finalOptions.extra) {
      if (typeof finalOptions.extra === 'function') {
        extraStyles = finalOptions.extra(theme)
      } else {
        extraStyles = finalOptions.extra
      }
    }
    return createStyles(
      merge(
        {
          root: { position: 'fixed', top: 0, width: '100%', height: '100vh', padding: '3rem' },
          title: {
            position: 'absolute',
            left: '3rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            color: finalOptions.titleColor,
            [theme.breakpoints.down('sm')]: {
              left: '1rem',
              right: '1rem',
            },
          },
          button: {
            color: finalOptions.buttonColor,
            borderColor: finalOptions.buttonColor,
            marginTop: '2rem',
            '&.active': {
              pointerEvents: 'all',
            },
          },
          buttonLabel: {
            color: finalOptions.buttonColor,
          },
          article: {
            position: 'absolute',
            top: '3rem',
            left: '3rem',
            right: '3rem',
            bottom: '3rem',
            maxWidth: '60%',
            overflow: 'hidden',
            color: finalOptions.articleColor,
            maskImage: articleMask,
            WebkitMaskImage: articleMask,
            [theme.breakpoints.up('lg')]: {
              width: '45%',
            },
            [theme.breakpoints.up('xl')]: {
              width: '30%',
            },
            [theme.breakpoints.down('sm')]: {
              maxWidth: 'unset',
              left: '1rem',
              right: '1rem',
            },
          },
          content: {
            padding: '3rem 0',
            boxSizing: 'border-box',
          },
        },
        extraStyles
      )
    )
  })
}
export type IntroClickEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent>
export function suppressIntroBgClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  e.stopPropagation()
  return false
}
