import { createStyles, withStyles } from '@material-ui/styles'
import React from 'react'
import Loading from '../common/Loading'

interface Props {
  id: string
  file: string
  classes: Record<string, string>
}
interface State {
  loading: boolean
  fallbackUrl?: string
}

class Gist extends React.PureComponent<Props, State> {
  private iframeNode: HTMLIFrameElement | null = null
  constructor(props: Props) {
    super(props)
    this.state = { loading: true }
  }

  componentDidMount() {
    this._updateIframeContent()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.file === this.props.file && prevProps.id === this.props.id) {
      return
    }
    this._updateIframeContent()
  }

  _defineUrl() {
    const { id, file } = this.props

    const fileArg = file ? `?file=${file}` : ''

    return {
      script: `https://gist.github.com/${id}.js${fileArg}`,
      page: `https://gist.github.com/${id}`,
    }
  }

  _updateIframeContent() {
    if (!this.iframeNode) {
      return
    }
    const iframe = this.iframeNode
    const doc = iframe.contentDocument || iframe.contentWindow?.document
    if (!doc) {
      return
    }

    const { id, file } = this.props
    const { script: gistScriptUrl, page: gistPageUrl } = this._defineUrl()
    const gistScript = `<script type="text/javascript" src="${gistScriptUrl}"></script>`
    const styles = '<style>*{font-size:12px;}</style>'
    const elementId = file ? `gist-${id}-${file}` : `gist-${id}`
    const resizeScript = `onload="parent.document.getElementById('${elementId}').style.height=document.body.scrollHeight + 'px'"`
    const iframeHtml = `<html><head><base target="_parent">${styles}</head><body ${resizeScript}>${gistScript}</body></html>`

    iframe.onload = () => this._onGistLoaded(doc)
    doc.open()
    doc.writeln(iframeHtml)
    doc.close()
    this.setState({ loading: true, fallbackUrl: gistPageUrl })
  }

  private _onGistLoaded(doc: Document) {
    const succ = !!doc.querySelector('.gist')
    if (succ) {
      this.setState({ loading: false })
    }
  }

  render() {
    const { id, file } = this.props

    return (
      <>
        {this.state.loading ? (
          <div className={this.props.classes.root}>
            <Loading />
            <span className={this.props.classes.hint}>正在加载代码片段&hellip;</span>
            {this.state.fallbackUrl ? (
              <span className={this.props.classes.hint}>
                如无法正常显示，请访问
                <a href={this.state.fallbackUrl} target="_blank" rel="noreferrer">
                  Gist页面
                </a>
              </span>
            ) : null}
          </div>
        ) : null}
        <iframe
          ref={n => {
            this.iframeNode = n
          }}
          width="100%"
          frameBorder={0}
          id={file ? `gist-${id}-${file}` : `gist-${id}`}
          style={{ height: 0 }}
        />
      </>
    )
  }
}

const styles = createStyles({
  root: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
  hint: {
    fontSize: '.8rem',
    color: '#888',
  },
})
export default withStyles(styles)(Gist)
