import { CssBaseline, Theme, ThemeProvider } from '@material-ui/core'
import darkTheme from '@src/theme/iron-fire-dark'
import lightTheme from '@src/theme/iron-fire-light'
import React from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { ReduxState, ThemeType } from '../../@data/redux/store'

interface Props {
  theme: ThemeType
}

function Root(props: React.PropsWithChildren<Props>) {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <link
          href="https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;600&family=Fira+Code:wght@400;600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <ThemeProvider<Theme> theme={props.theme === ThemeType.Dark ? darkTheme : lightTheme}>
        <CssBaseline>{props.children}</CssBaseline>
      </ThemeProvider>
    </>
  )
}

function mapStateToProps(state: ReduxState) {
  return {
    theme: state.theme,
  }
}

export default connect(mapStateToProps)(Root)
