import { Box, Typography } from '@material-ui/core'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { BlogListData } from '../@data/blog'
import SEO from '../components/common/SEO'
import Layout from '../components/frame/Layout'
import BlogList from '../components/overview/BlogList'

interface Props {
  site: { siteMetadata: { title: string } }
  allMdx: BlogListData
}
interface Context {
  category: string
}

export default function CategoryPage({ data, pageContext, location }: PageProps<Props, Context>) {
  const { title } = data.site.siteMetadata
  const { category } = pageContext
  const posts = data.allMdx
  const cateHeader = (
    <Typography>
      <strong>{category}</strong>&nbsp;专栏下有{posts.totalCount}篇文章
    </Typography>
  )
  return (
    <Layout location={location} title={title}>
      <SEO title={`专栏"${category}"的文章列表`} />
      <Box mb={3}>{cateHeader}</Box>
      <BlogList posts={posts} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($category: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 100
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } }, published: { eq: true } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
            sub
          }
          frontmatter {
            title
            tags
            description
            category
            date
            draft
          }
        }
      }
    }
  }
`
