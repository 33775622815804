import { faList } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Drawer, Fab } from '@material-ui/core'
import React, { useState } from 'react'
import { TOCModel } from '../../@data/blog'
import TOC from './TOC'

interface Props {
  toc: TOCModel
}
interface State {
  drawerOpen: boolean
}

export default function TOCDrawer(props: Props): JSX.Element {
  const [state, setState] = useState<State>()
  const toggleDrawer = (open: boolean) => () => {
    setState({ drawerOpen: open })
  }
  return (
    <React.Fragment>
      <Fab
        color="secondary"
        size="medium"
        onClick={toggleDrawer(true)}
        aria-label="table of contents"
        style={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: 999 }}
      >
        <FontAwesomeIcon icon={faList} size="lg" />
      </Fab>
      <Drawer anchor="right" open={state?.drawerOpen} onClose={toggleDrawer(false)}>
        <Box py={1.5}>
          <TOC toc={props.toc} style={{ flex: '1' }} />
        </Box>
      </Drawer>
    </React.Fragment>
  )
}
