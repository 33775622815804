import { faArrowToTop, faComments, faList } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, List, ListItem, ListItemText } from '@material-ui/core'
import { CSSProperties } from '@material-ui/styles'
import React, { ReactElement, useState } from 'react'
import { Link as ScrollLink } from 'react-scroll'
import { TOCItemModel, TOCModel } from '../../@data/blog'

interface Props {
  toc: TOCModel
  style?: CSSProperties
}

interface State {
  active?: string
}

export default function TOC({ toc, ...props }: Props): ReactElement {
  // const items: ReactElement[] = []
  const [state, setState] = useState<State>()
  const updateActive = function (anchor: string) {
    setState({ active: anchor })
  }
  const makeItems = (children: TOCItemModel[] | undefined, depth: number) => {
    const result: ReactElement[] = []
    if (children && children.length > 0) {
      children.forEach(child => {
        result.push(
          <TOCItem
            key={child.url}
            to={child.url.substr(1)} // Trims the leading '#'
            selected={state?.active === child.url}
            onSetActive={() => updateActive(child.url)}
          >
            <ListItemText
              disableTypography
              primary={
                <Box pl={depth + 1.5} fontSize="caption.fontSize">
                  {child.title}
                </Box>
              }
            />
          </TOCItem>,
          ...makeItems(child.items, depth + 1)
        )
      })
    }
    return result
  }
  return (
    <List
      disablePadding
      {...props}
      style={{ ...props.style, flex: '0 0 auto', display: 'flex', flexDirection: 'column' }}
    >
      <TOCItem
        to="post-title"
        selected={state?.active === 'post-title'}
        onSetActive={() => updateActive('post-title')}
      >
        <ListItemText
          disableTypography
          primary={
            <Box fontSize="subtitle2.fontSize" display="flex">
              <FontAwesomeIcon icon={faArrowToTop} />
              <Box component="span" ml={1}>
                回到标题
              </Box>
            </Box>
          }
        />
      </TOCItem>
      <ListItem style={{ paddingLeft: '2em', paddingRight: '2em' }}>
        <Box fontSize="subtitle2.fontSize" display="flex" alignItems="center">
          <FontAwesomeIcon icon={faList} />
          <Box component="span" ml={1}>
            文章目录
          </Box>
        </Box>
      </ListItem>
      <List disablePadding style={{ overflow: 'auto', flex: '1 1 auto' }}>
        {makeItems(toc.items, 0).map(item => item)}
      </List>
      <TOCItem
        to="comments"
        selected={state?.active === 'comments'}
        onSetActive={() => updateActive('comments')}
      >
        <ListItemText
          disableTypography
          primary={
            <Box fontSize="subtitle2.fontSize" display="flex">
              <FontAwesomeIcon icon={faComments} />
              <Box component="span" ml={1}>
                评论区
              </Box>
            </Box>
          }
        />
      </TOCItem>
    </List>
  )
}

function TOCItem(props: {
  to: string
  selected: boolean
  onSetActive: (to: string) => void
  children?: any
}) {
  return (
    <ListItem
      button
      to={props.to}
      selected={props.selected}
      component={ScrollLink}
      spy={true}
      smooth={true}
      duration={500}
      offset={-100}
      delay={0}
      onSetActive={props.onSetActive}
      style={{ paddingLeft: '2em', paddingRight: '2em' }}
    >
      {props.children}
    </ListItem>
  )
}
