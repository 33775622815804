import { faBooks, faTags } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Hidden,
  Typography,
} from '@material-ui/core'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import { BlogListData } from '../@data/blog'
import navs from '../@data/navs'
import TagList from '../components/blog/TagList'
import Bio from '../components/common/Bio'
import SEO from '../components/common/SEO'
import Layout from '../components/frame/Layout'
import BlogList from '../components/overview/BlogList'
import CategoryMenu from '../components/overview/CategoryMenu'

interface Props {
  site: { siteMetadata: { title: string; description: string } }
  posts: BlogListData
}
interface Context {
  sub: string
  name: Record<string, string>
}

export default function SubBlogIndex({ data, location, pageContext }: PageProps<Props, Context>) {
  const { title, description } = data.site.siteMetadata
  const posts = data.posts
  const { sub } = pageContext
  const currentNav = navs.getCurrentNav(location)
  const pageTitle = currentNav?.name.locale || '文章列表'
  const pageDesc = currentNav?.description.locale || description

  const catTitle = (
    <Box display="flex" alignItems="baseline">
      <Box mr={1}>
        <FontAwesomeIcon icon={faBooks} />
      </Box>
      <Typography variant="subtitle1">专栏</Typography>
    </Box>
  )
  const tagTitle = (
    <Box display="flex" alignItems="baseline">
      <Box mr={1}>
        <FontAwesomeIcon icon={faTags} />
      </Box>
      <Typography variant="subtitle1">标签</Typography>
    </Box>
  )
  return (
    <Layout location={location} title={title}>
      <SEO title={pageTitle} description={pageDesc} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={9}>
          <BlogList posts={posts} />
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={3}>
            {catTitle}
            <CategoryMenu categories={posts.categories} sub={sub} />
            <Box my={2}>
              <Divider />
            </Box>
            <Box pb={1}>{tagTitle}</Box>
            <TagList tags={posts.tags.map(tag => tag.name)} sub={sub} />
            <Box my={2}>
              <Divider />
            </Box>
            <Bio />
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <Box mb={5} style={{ width: '100%' }}>
              <ExpansionPanel>
                <ExpansionPanelSummary>{catTitle}</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <CategoryMenu categories={posts.categories} sub={sub} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel>
                <ExpansionPanelSummary>{tagTitle}</ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <TagList tags={posts.tags.map(tag => tag.name)} sub={sub} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Box>
            <Bio />
          </Grid>
        </Hidden>
      </Grid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($glob: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    posts: allMdx(
      filter: { fields: { slug: { glob: $glob } }, published: { eq: true } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            sub
          }
          frontmatter {
            date
            title
            tags
            description
            category
            draft
          }
        }
      }
      tags: group(field: frontmatter___tags) {
        name: fieldValue
      }
      categories: group(field: frontmatter___category) {
        name: fieldValue
      }
    }
  }
`
