import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/components/frame/Layout.tsx";
import { faCopyright, faCandyCane, faBook, faGraduationCap, faComputerSpeaker, faTablet, faServer, faChartLine, faPalette } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Bio from '../components/common/Bio';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "关于我"
    }}>{`关于我`}</h1>
    <Bio mdxType="Bio" />
    <p>{`85 后，北京人，混迹于武汉和深圳 N 年，现居杭州。Google 粉，微软粉，苹果路人。`}</p>
    <p>{`目前为独立全栈开发者，主攻前端开发及游戏客户端开发，后端目前主要精力放在`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Golang`}</code>{`上。`}</p>
    <h2 {...{
      "id": "编程生涯"
    }}>{`编程生涯`}</h2>
    <FontAwesomeIcon icon={faCandyCane} style={{
      marginRight: '1em',
      color: 'orange'
    }} mdxType="FontAwesomeIcon" />
童年时代
    <ul>
      <li parentName="ul">{`90 年代初开始学习 IBM 的`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`PC-DOS`}</code>{`，从此与计算机结下了不解之缘。`}</li>
      <li parentName="ul">{`从`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Windows 3.1`}</code>{`时代开始接触图形化操作系统，`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Windows 95`}</code>{`面世后开始自学`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`HTML`}</code>{`并做了一堆没什么卵用的站点瞎玩儿。`}</li>
    </ul>
    <FontAwesomeIcon icon={faBook} style={{
      marginRight: '1em',
      color: 'orange'
    }} mdxType="FontAwesomeIcon" />
中学时代
    <ul>
      <li parentName="ul">{`初中开始学习`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`C 语言`}</code>{`，当时的物理老师是微软技术专家，也是我编程的启蒙恩师。`}</li>
      <li parentName="ul">{`中学时代自学`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`3DS Max`}</code>{`，后来发现美术功底才是最重要的 🤦‍♂️ 生于美术世家却没有好好学美术 😂`}</li>
      <li parentName="ul">{`中学同期自学`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Flash`}</code>{`和`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`ActionScript`}</code></li>
    </ul>
    <FontAwesomeIcon icon={faGraduationCap} style={{
      marginRight: '1em',
      color: 'orange'
    }} mdxType="FontAwesomeIcon" />
大学时代
    <ul>
      <li parentName="ul">{`本科毕业于于武汉大学软件工程专业，网络编程协会的 Flash 讲师`}</li>
      <li parentName="ul">{`于苏格兰`}<em parentName="li">{`阿伯丁大学 (University of Aberdeen)`}</em>{`商学院获得`}<em parentName="li">{`创新产品开发和企业管理`}</em>{`的硕士学位`}</li>
    </ul>
    <FontAwesomeIcon icon={faComputerSpeaker} style={{
      marginRight: '1em',
      color: 'orange'
    }} mdxType="FontAwesomeIcon" />
职业历程
    <ul>
      <li parentName="ul">{`前期主攻桌面端应用开发，在`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`WinForm`}</code>{`、`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`WPF`}</code>{`、`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`WCF`}</code>{`和`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`MFC`}</code>{`等框架上投入了多年精力`}</li>
      <li parentName="ul">{`后来转到了梦寐以求的游戏开发，研究过`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`XNA`}</code>{`，主攻`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Unity 3D`}</code>{`，用`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Cocos Creator`}</code>{`和`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Godot`}</code>{`完成过一些小项目`}</li>
      <li parentName="ul">{`曾参加 48 小时极限游戏开发比赛`}<em parentName="li">{`Ludum Dare`}</em>{`（Solo 组）的第 38、40、41、43 期，`}<a parentName="li" {...{
          "href": "https://ldjam.com/users/wizcas/games"
        }}>{`作品列表`}</a></li>
      <li parentName="ul">{`Web 前端主攻`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Angular`}</code>{`（工程化大项目）和`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`React`}</code>{`（中小型项目），用`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Vue`}</code>{`做过一个管理系统，但是不太喜欢。`}</li>
      <li parentName="ul">{`目前移动端的项目均用`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Flutter`}</code>{`开发，很喜欢`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`dart`}</code>{`语言。`}</li>
    </ul>
    <h2 {...{
      "id": "技术栈"
    }}>{`技术栈`}</h2>
    <h3 {...{
      "id": "语言类"
    }}>{`语言类`}</h3>
    <ul>
      <li parentName="ul">{`C#`}</li>
      <li parentName="ul">{`TypeScript & JavaScript`}</li>
      <li parentName="ul">{`Golang`}</li>
      <li parentName="ul">{`Dart`}</li>
      <li parentName="ul">{`Python`}</li>
      <li parentName="ul">{`HTML & CSS`}</li>
      <li parentName="ul">{`Bash Script`}</li>
      <li parentName="ul">{`C++`}</li>
    </ul>
    <h3 {...{
      "id": "框架类"
    }}>{`框架类`}</h3>
    <FontAwesomeIcon icon={faTablet} style={{
      marginRight: '1em',
      color: 'orange'
    }} mdxType="FontAwesomeIcon" />
前端框架
    <ul>
      <li parentName="ul">{`Angular`}</li>
      <li parentName="ul">{`React`}</li>
      <li parentName="ul">{`Electron`}</li>
      <li parentName="ul">{`Flutter`}</li>
      <li parentName="ul">{`WPF`}</li>
    </ul>
    <FontAwesomeIcon icon={faServer} style={{
      marginRight: '1em',
      color: 'orange'
    }} mdxType="FontAwesomeIcon" />
后端框架
    <ul>
      <li parentName="ul">{`gRPC`}</li>
      <li parentName="ul">{`gin`}</li>
      <li parentName="ul">{`koa`}</li>
      <li parentName="ul">{`Express.js`}</li>
      <li parentName="ul">{`Flask`}</li>
      <li parentName="ul">{`Tornado`}</li>
      <li parentName="ul">{`web.py`}</li>
    </ul>
    <FontAwesomeIcon icon={faChartLine} style={{
      marginRight: '1em',
      color: 'orange'
    }} mdxType="FontAwesomeIcon" />
数据分析
    <ul>
      <li parentName="ul">{`Pandas`}</li>
      <li parentName="ul">{`NumPy`}</li>
      <li parentName="ul">{`Anaconda`}</li>
    </ul>
    <FontAwesomeIcon icon={faPalette} style={{
      marginRight: '1em',
      color: 'orange'
    }} mdxType="FontAwesomeIcon" />
图形渲染
    <ul>
      <li parentName="ul">{`three.js`}</li>
      <li parentName="ul">{`PIXI.js`}</li>
      <li parentName="ul">{`Babylon.js`}</li>
    </ul>
    <h3 {...{
      "id": "游戏引擎"
    }}>{`游戏引擎`}</h3>
    <ul>
      <li parentName="ul">{`Unity 3D`}</li>
      <li parentName="ul">{`Cocos Creator`}</li>
      <li parentName="ul">{`Godot`}</li>
    </ul>
    <h3 {...{
      "id": "数据库"
    }}>{`数据库`}</h3>
    <ul>
      <li parentName="ul">{`PostgreSQL`}</li>
      <li parentName="ul">{`MySQL`}</li>
      <li parentName="ul">{`MongoDB`}</li>
      <li parentName="ul">{`Redis`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "游戏生涯"
    }}>{`游戏生涯`}</h2>
    <ul>
      <li parentName="ul">{`90 年代初沉迷 FC 游戏，水平拙计`}</li>
      <li parentName="ul">{`90 年代中期（小学 4 年级左右吧）有了第一台自己的`}<em parentName="li">{`联想 1+1 天蝎座`}</em>{`，除了学`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`HTML`}</code>{`外还用来玩 `}<em parentName="li">{`《雷曼》`}</em>{`（人生第一款育碧）和 `}<em parentName="li">{`《星际争霸》`}</em>{`（英文版没太玩明白）和 `}<em parentName="li">{`《金庸群侠传》`}</em>{`，水平依然拙计。`}<em parentName="li">{`《雷曼》`}</em>{`是我永远的心里阴影。`}</li>
      <li parentName="ul">{`199X 年 X 月在电脑报上看到了一篇讲西游记 MUD 的文章，震惊了。刚开始试着用`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`telnet`}</code>{`发现玩不了，后来好不容易才找到了`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`Zmud`}</code>{`。从此开启了网游生涯。之后每隔几年总会找个 MUD 服务器玩玩，那种感觉是其他游戏无法替代的。`}</li>
      <li parentName="ul">{`很早就知道了 `}<em parentName="li">{`《UO》`}</em>{` ，但是因为网速和客户端下载问题一直没能玩成。直到某年某月在《大软》上看到可爱多写的 UO 小说《平静的湖》，里面真挚的(?)感情和当时精美疯了的画面让我再也忍不了了，直接冲到我妈单位，利用公家网络资源弄到了梦寐以求的客户端。在里面度过了无数日日月月，还有当年网络游戏环境下的爱恨情仇。`}</li>
      <li parentName="ul">{`初中沉迷星际，高中沉迷 CS。当年还和几个同学写星际小说，一人负责写一个种族的故事，我负责虫族。`}</li>
      <li parentName="ul">{`高中马路对面的中发电子市场里有一个挂着“第三波”牌子的摊位，专卖高质量 D 版盘。多亏了卖盘的小两口，我才一直没有和国际单机游戏市场脱节。`}</li>
      <li parentName="ul">{`靠 `}<em parentName="li">{`《无冬之夜》`}</em>{` 大幅提高了英语阅读水平。`}</li>
      <li parentName="ul">{`非典期间停课几个月，和一堆同学一起沉迷各种网游，玩的最多的是 `}<em parentName="li">{`《RO》`}</em>{`。`}</li>
      <li parentName="ul">{`大学 `}<em parentName="li">{`《魔兽世界》`}</em>{` 公测，和当时 `}<em parentName="li">{`《EQ》`}</em>{` 工会 `}<em parentName="li">{`索兰尼亚骑士团`}</em>{` 的战友一起等到凌晨进了游戏。玩到暑假左右时，发现自己再也找不回当年在网游中的感动了。从此基本再没有怎么好好玩过 MMORPG。`}</li>
      <li parentName="ul">{`大学时期网上跑团，开启了新世界大门。主要是跑 D&D 的团，并恶补了大量 D&D 文学作品和设定集。和跑团众一起录广播剧。`}</li>
      <li parentName="ul">{`现已陷入`}<strong parentName="li">{`喜+1`}</strong>{`的死循环中，Steam 目前库存`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`238`}</code>{`。`}</li>
    </ul>
    <h2 {...{
      "id": "最喜欢的游戏们"
    }}>{`最喜欢的游戏们`}</h2>
    <details>
      <summary>排名不分先后</summary>
      <ul>
        <li parentName="ul">
          <p parentName="li">{`UO`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`神界：原罪 系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`老滚系列（从 3 开始，之前的没玩过）`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`辐射系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`盟军系列（4 不算）`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`刺客信条系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`荒野大表哥系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`杀手系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`全面战争系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`博得之门系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`无冬之夜系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`过山车大亨 1 & 2`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`模拟人生系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Arma 系列（+原闪电行动）`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Dirt 系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`微软飞行模拟系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`骑砍系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`CS 系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`战神系列`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`暴雨`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Elite：Dangerous`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`MineCraft`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`Subnautica`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`金庸群侠传`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`傲世三国`}</p>
        </li>
        <li parentName="ul">
          <p parentName="li">{`侠客行类 MUD`}</p>
        </li>
      </ul>
    </details>
    <hr></hr>
    <h1 {...{
      "id": "关于本站"
    }}>{`关于本站`}</h1>
    <h2 {...{
      "id": "更新日志"
    }}>{`更新日志`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`2020.05.31`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`添加暗色主题`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`2020.05.29`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`完成“关于”页`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`2020.05.28`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`编写`}<code parentName="td" {...{
              "className": "language-text"
            }}>{`ScrollMaster`}</code>{`自制库，完成拥有页面滚动动画的站点首页`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`2020.05.24`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`完成子博客的架构支持和逻辑调整`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`2020.05.23`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`完成博客文章目录边栏功能`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`2020.05.21`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`引入`}<code parentName="td" {...{
              "className": "language-text"
            }}>{`MDX`}</code>{`解析和页面生成`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`2020.05.20`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`添加`}<em parentName="td">{`Valine`}</em>{`评论系统`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`2020.05.19`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`完成基础功能和布局样式`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`2020.05.15`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`开始建站`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      