import { faChevronDoubleDown } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, fade, Theme, Typography } from '@material-ui/core'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import gsap from 'gsap'
import React, { useEffect, useRef } from 'react'
import {
  AnimatedPageProps,
  IntroClickEvent,
  PageArticle,
  suppressIntroBgClick,
  useIntroStyles,
} from './common'

const useStyles = useIntroStyles({
  titleColor: fade('#7F540F', 0.7),
  articleColor: '#333',
  buttonColor: '#333',
  extra: (theme: Theme) => ({
    article: {
      left: '50%',
      [theme.breakpoints.down('sm')]: {
        top: '9rem',
      },
    },
    hint: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'black',
      position: 'fixed',
      left: '3rem',
      bottom: '2rem',
      [theme.breakpoints.down('sm')]: {
        left: 0,
        right: 0,
      },
      '& svg': {
        marginTop: '.5em',
      },
    },
  }),
})

export default function BitsPage(props: AnimatedPageProps) {
  const { mdx } = useStaticQuery<PageArticle>(graphql`
    {
      mdx(fields: { slug: { eq: "/intro/bits/" } }) {
        frontmatter {
          title
        }
        body
      }
    }
  `)
  const rootRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)
  const articleRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const hintRef = useRef(null)
  const arrowRef = useRef(null)
  useEffect(() => {
    const root = rootRef.current
    const article = articleRef.current
    const hint = hintRef.current
    const arrow = arrowRef.current
    const anim = gsap
      .timeline()
      .set(root, { y: '0%' })
      .fromTo(root, 1, { scale: 1.5, opacity: 0 }, { scale: 1, opacity: 1 }, 'appear')
      .fromTo(article, 1, { y: '50%', opacity: 0 }, { y: '0%', opacity: 1 }, 'appear')
      .fromTo(hint, 0.5, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, delay: 0.5 }, 'appear')
      .to(root, 1, { y: '-50%', opacity: 0 })
      .pause()
      .progress(props.progress)
    const arrowAnim = gsap.fromTo(
      arrow,
      0.5,
      { y: '0%' },
      { y: '30%', repeat: -1, yoyo: true, ease: 'sine.inOut' }
    )
    return () => {
      anim.kill()
      arrowAnim.kill()
    }
  })
  const classes = useStyles()
  return (
    <div ref={rootRef} className={classes.root} style={{ opacity: 0 }}>
      <div className={classes.title} ref={titleRef}>
        <Typography variant="h2">{mdx.frontmatter.title}</Typography>
        <Button
          component={Link}
          to="/blog/bits"
          variant="outlined"
          classes={{ root: classes.button, label: classes.buttonLabel }}
          className={props.progress > 0.4 && props.progress < 0.6 ? 'active' : ''}
          style={{ marginTop: '2rem' }}
          onClick={(e: IntroClickEvent) => {
            // window.scrollTo({ top: 0 })
            suppressIntroBgClick(e)
          }}
        >
          随便瞧瞧
        </Button>
      </div>
      <div className={classes.article} ref={articleRef}>
        <div className={classes.content} ref={contentRef}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </div>
      </div>
      <div ref={hintRef} className={classes.hint}>
        <Typography variant="caption">TAP TO CONTINUE</Typography>
        <div ref={arrowRef}>
          <FontAwesomeIcon icon={faChevronDoubleDown} />
        </div>
      </div>
    </div>
  )
}
