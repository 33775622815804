module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/vercel/path0/src/components/frame/Layout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":590}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 1.0725rem"}},{"resolve":"gatsby-remark-prismjs","options":{"showLineNumbers":false,"prompt":{"user":"0x1C","host":"dev","global":true}}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants"],"rehypePlugins":[null]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-166967003-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"0x1C.dev","short_name":"0x1C","start_url":"/","background_color":"#FAFAFA","theme_color":"#3A9FAA","display":"minimal-ui","icon":"content/assets/logo-min.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#3A9FAA","showSpinner":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-valine/gatsby-browser.js'),
      options: {"plugins":[],"appId":"gyaL7rh9UhITd5iK7oVahxqi-gzGzoHsz","appKey":"td7SYIyjOQBYgPtDqtKz6Dmh","requiredFields":["nick"],"placeholder":"请留下您的感想吧～\n评论支持Markdown语法✍🏻️️","avatar":"robohash","serverURLs":"https://leanapi.0x1c.tech","visitor":true,"emojiCDN":"https://cdn.jsdelivr.net/gh/blogimg/emotion@1493a3f41141db65b9fbcb589cb1e39a4168f6da/aru/","emojiMaps":{"高兴":"1.png","皱眉":"2.png","笑而不语":"3.png","斜眼":"4.png","懵比":"10.png","难过":"11.png","恳求":"14.png","泪流满面":"18.png","血流满面":"19.png","可爱":"20.png","撅嘴":"21.png","流汗":"22.png","黑线":"23.png","困":"24.png","痴呆":"26.png","抠鼻":"27.png","抠鼻血":"28.png","吐血":"29.png","吐舌":"34.png","口罩":"35.png","闭嘴":"36.png","机智":"39.png","喷水":"44.png","喷血":"45.png","死了":"46.png","吹套":"50.png","赞":"52.png","踩":"53.png","SB":"57.png","OK":"58.png","摊手":"60.png","打脸":"258.png","被打脸":"62.png","挨打":"65.png","思考":"66.png","抱拳":"68.png","放学别走":"71.png","加一":"173.png","减一":"174.png","搬砖":"196.png","工作":"242.png","击掌":"249.png","喂屎":"240.png","干杯":"248.png","鸡腿":"155.png","老司机":"244.png","吃瓜":"231.png","猫盒":"137.png","撸猫":"134.png","老板":"112.png","装逼":"111.png","狗头":"120.png"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
