import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

export default function Loading() {
  const query = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "loading.svg" }) {
        publicURL
      }
    }
  `) as { logo: { publicURL: string } }
  return <img src={query.logo.publicURL} width="80" height="80" />
}
