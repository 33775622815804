import { makeStyles, Theme } from '@material-ui/core'
import { createStyles } from '@material-ui/styles'
import Valine from 'gatsby-plugin-valine'
import React from 'react'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    comment: {
      '& .veditor,& .vinput': {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary + ' !important',
      },
      '& .veditor,& .vinput::placeholder': {
        color: theme.palette.action.disabled,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.caption.fontSize,
      },
      '& .vempty,& .vpower': {
        color: theme.palette.text.hint + ' !important',
      },
      '& .vat': {
        color: theme.palette.secondary.main + ' !important',
      },
      '& p': {
        color: theme.palette.text.primary + ' !important',
      },
      '& a': {
        color: theme.palette.primary.main + ' !important',
      },
      '& a:hover': {
        color: theme.palette.secondary.main + ' !important',
        textDecoration: 'underline !important',
      },
    },
  })
)

const Comment = (props: { slug: string }) => {
  const classse = useStyles()
  return <Valine id="comments" path={props.slug} className={classse.comment} />
}
export default Comment
