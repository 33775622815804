import { graphql, PageProps } from 'gatsby'
import React from 'react'
import SEO from '../components/common/SEO'
import Layout from '../components/frame/Layout'

interface Data {
  site: {
    siteMetadata: {
      title: string
    }
  }
}

const NotFoundPage = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: 你懂的" />
      <h1>Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
