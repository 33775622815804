/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import {
  faGithub,
  faLinkedin,
  faSteam,
  faTelegram,
  faTwitter,
  faZhihu,
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Box, makeStyles, Theme, Typography } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

interface AvatarData {
  childImageSharp: {
    fixed: {
      src: string
    }
  }
}
interface SiteMetadata {
  siteMetadata: {
    author: {
      name: string
      email: string
      summary: string
    }
    social: {
      twitter: string
      github: string
      telegram: string
      steam: string
      zhihu: string
      linkedin: string
    }
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  icons: {
    marginRight: theme.spacing() * 1,
  },
}))

const Bio = () => {
  const data: { site: SiteMetadata; avatar: AvatarData } = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
        childImageSharp {
          fixed(width: 80, height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            email
            summary
          }
          social {
            twitter
            github
            telegram
            steam
            zhihu
            linkedin
          }
        }
      }
    }
  `)

  const avatarImg = data.avatar.childImageSharp.fixed
  const { author, social } = data.site.siteMetadata
  const classes = useStyles()
  return (
    <Box display="flex" alignItems="flex-start">
      <Box mr={2}>
        <Avatar alt={author.name} src={avatarImg.src} style={{ width: 60, height: 60 }}></Avatar>
      </Box>
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="baseline" flexWrap="wrap">
          <Box mr={1} fontSize="subtitle2.fontSize" fontWeight="fontWeightBold">
            {author.name}
          </Box>
          <Typography variant="caption" color="textSecondary">
            {author.summary}
          </Typography>
        </Box>
        <Box display="flex" mt={0.5} flexWrap="wrap">
          <a href={`mailto:${author.email}`} className={classes.icons}>
            <FontAwesomeIcon icon={faEnvelope} size="lg" />
          </a>
          <a
            href={`https://t.me/${social.telegram}`}
            target="_blank"
            rel="noreferrer"
            className={classes.icons}
          >
            <FontAwesomeIcon icon={faTelegram} size="lg" />
          </a>
          <a
            href={`https://twitter.com/${social.twitter}`}
            target="_blank"
            rel="noreferrer"
            className={classes.icons}
          >
            <FontAwesomeIcon icon={faTwitter} size="lg" />
          </a>
          <a
            href={`https://www.linkedin.com/in/${social.linkedin}`}
            target="_blank"
            rel="noreferrer"
            className={classes.icons}
          >
            <FontAwesomeIcon icon={faLinkedin} size="lg" />
          </a>
          <a
            href={`https://github.com/${social.github}`}
            target="_blank"
            rel="noreferrer"
            className={classes.icons}
          >
            <FontAwesomeIcon icon={faGithub} size="lg" />
          </a>
          <a
            href={`https://steamcommunity.com/id/${social.steam}`}
            target="_blank"
            rel="noreferrer"
            className={classes.icons}
          >
            <FontAwesomeIcon icon={faSteam} size="lg" />
          </a>
          <a
            href={`https://zhihu.com/people/${social.zhihu}`}
            target="_blank"
            rel="noreferrer"
            className={classes.icons}
          >
            <FontAwesomeIcon icon={faZhihu} size="lg" />
          </a>
        </Box>
      </Box>
    </Box>
  )
}

export default Bio
