import { Box } from '@material-ui/core'
import React from 'react'
import { BlogListData } from '../../@data/blog'
import BlogCard from './BlogCard'

export default function BlogList(props: { posts: BlogListData }) {
  return (
    <Box display="flex" flexDirection="column">
      {props.posts.edges.map(({ node }) => {
        return (
          <Box my={1} key={node.fields.slug}>
            <BlogCard blog={node}></BlogCard>
          </Box>
        )
      })}
    </Box>
  )
}
