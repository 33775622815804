import { faChevronDoubleDown } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, fade, Theme, Typography } from '@material-ui/core'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import gsap from 'gsap'
import React, { useEffect, useRef } from 'react'
import {
  AnimatedPageProps,
  IntroClickEvent,
  PageArticle,
  suppressIntroBgClick,
  useIntroStyles,
} from './common'

const useStyles = useIntroStyles({
  titleColor: fade('#6CB6FF', 0.7),
  extra: (theme: Theme) => ({
    title: {
      alignItems: 'flex-end',
      right: '5rem',
    },
    article: {
      [theme.breakpoints.down('sm')]: {
        top: '20%',
        bottom: '6rem',
      },
    },
    hint: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'white',
      position: 'fixed',
      right: '5rem',
      bottom: '2rem',
      [theme.breakpoints.down('sm')]: {
        left: 0,
        right: 0,
      },
      '& svg': {
        marginTop: '.5em',
      },
    },
  }),
})

export default function DevPage(props: AnimatedPageProps) {
  const { mdx } = useStaticQuery<PageArticle>(graphql`
    {
      mdx(fields: { slug: { eq: "/intro/dev/" } }) {
        frontmatter {
          title
        }
        body
      }
    }
  `)
  const rootRef = useRef(null)
  const titleRef = useRef<HTMLDivElement>(null)
  const articleRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const hintRef = useRef(null)
  const arrowRef = useRef(null)
  useEffect(() => {
    const root = rootRef.current
    const title = titleRef.current
    const article = articleRef.current
    const content = contentRef.current
    const contentScrollRange =
      content && article ? content?.scrollHeight - article?.clientHeight : 0
    const hint = hintRef.current
    const arrow = arrowRef.current
    const anim = gsap
      .timeline()
      .set(article, { y: '0%', scale: 1 })
      .set(content, { y: 0 })
      .fromTo(root, 0.5, { opacity: 0 }, { opacity: 1 }, 'appear')
      .fromTo(title, 1, { x: '100%', opacity: 0 }, { x: '0%', opacity: 1 }, 'appear')
      .fromTo(hint, 0.5, { y: '-100%', opacity: 0 }, { y: '0%', opacity: 1, delay: 0.5 }, 'appear')
      .fromTo(article, 1, { x: '50vw', opacity: 0 }, { x: '0vw', opacity: 1, delay: 0.2 }, 'appear')
      .to(content, 2, { y: content ? -1 * contentScrollRange : 0 }, 'out')
      .to(article, 1, { scale: 1.5, opacity: 0, delay: 1 }, 'out')
      .to(title, 1, { x: '-50vw', opacity: 0, delay: 1 }, 'out')
      .fromTo(hint, 1, { scale: 1 }, { scale: 0 })
      .pause()
      .progress(props.progress)
    const arrowAnim = gsap.fromTo(
      arrow,
      0.5,
      { y: '0%' },
      { y: '30%', repeat: -1, yoyo: true, ease: 'sine.inOut' }
    )
    return () => {
      anim.kill()
      arrowAnim.kill()
    }
  })

  const classes = useStyles()
  return (
    <div className={classes.root} ref={rootRef} style={{ opacity: 0 }}>
      <div className={classes.article} ref={articleRef}>
        <div className={classes.content} ref={contentRef}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </div>
      </div>
      <div className={classes.title} ref={titleRef}>
        <Typography variant="h2">{mdx.frontmatter.title}</Typography>
        <Button
          component={Link}
          to="/blog/dev"
          variant="outlined"
          classes={{ root: classes.button, label: classes.buttonLabel }}
          className={props.progress > 0.2 && props.progress < 0.52 ? 'active' : ''}
          style={{ marginTop: '2rem' }}
          onClick={(e: IntroClickEvent) => {
            // requestAnimationFrame(() => {
            //   window.scrollTo({ top: 0 })
            // })
            suppressIntroBgClick(e)
          }}
        >
          阅读手记
        </Button>
      </div>
      <div ref={hintRef} className={classes.hint}>
        <Typography variant="caption">TAP TO CONTINUE</Typography>
        <div ref={arrowRef}>
          <FontAwesomeIcon icon={faChevronDoubleDown} />
        </div>
      </div>
    </div>
  )
}
