import React, { HTMLAttributes } from 'react'
import { AnimationUpdater, clampProgress, ScrollContext, ScrollState } from './common'

type SlaveChildrenRenderer =
  | ((state: ScrollState, ref?: React.RefObject<any>) => React.ReactNode)
  | React.ReactNode

export interface SlaveProps<T> {
  id?: string
  start?: number
  end?: number
  stopAt?: number
  onPlay?: AnimationUpdater
  onStop?: AnimationUpdater
  onUpdate?: AnimationUpdater
  children?: SlaveChildrenRenderer
  context?: T
}
type PropsType<T> = SlaveProps<T> &
  Omit<React.DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'children'>

export default class ScrollSlave<T = any> extends React.Component<PropsType<T>> {
  static contextType = ScrollContext
  private _ref: React.RefObject<HTMLElement>
  private _playing = false
  private _innerState?: ScrollState
  constructor(props: PropsType<T>) {
    super(props)
    this._ref = React.createRef()
  }

  componentDidMount() {
    this._callPlayHook(this._playing)
  }

  get _isDbg() {
    return this._ref?.current?.id === 'a'
  }

  private _callPlayHook(isPlay: boolean) {
    if (this._innerState) {
      if (isPlay && this.props.onPlay) {
        this.props.onPlay(this._innerState, this._ref.current)
      } else if (!isPlay && this.props.onStop) {
        this.props.onStop(this._innerState, this._ref.current)
      }
    }
  }

  render() {
    const ctx = this.context as ScrollState
    const { children } = this.props
    const start = this.props.start || 0
    const end = this.props.end || 1

    const parentProgress = ctx.progress
    const playing = parentProgress > start && parentProgress <= end && end > start
    const progress = end === start ? 1 : clampProgress((parentProgress - start) / (end - start))

    // We need a new scroll state so to pass the Direct Slaves' progress down
    this._innerState = Object.assign<{}, ScrollState, Partial<ScrollState>>({}, ctx, {
      progress: progress,
    }) as ScrollState

    if (!this._playing && playing) {
      this._callPlayHook(true)
    } else if (this._playing && !playing) {
      this._callPlayHook(false)
    }
    this._playing = playing
    if (playing && this.props.onUpdate) {
      this.props.onUpdate(this._innerState, this._ref.current)
    }

    return (
      <>
        <ScrollContext.Provider value={this._innerState}>
          {children
            ? typeof children === 'function'
              ? (children as Function)(this._innerState, this._ref)
              : children
            : null}
        </ScrollContext.Provider>
      </>
    )
  }
}
