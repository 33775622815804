import { faEye } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'
import AV from 'leancloud-storage'
import React, { useEffect, useState } from 'react'

interface Props {
  slug: string
}

export function PageReaderCounter(props: Props) {
  const [count, updateCount] = useState<number | null>(null)
  useEffect(() => {
    const query = new AV.Query('Counter')
    query.equalTo('xid', props.slug)
    query.find().then(results => {
      let count = 0
      if (results && results.length > 0) {
        count = results[0].get('time')
      }
      updateCount(count)
    })
  }, [props.slug])
  return (
    <Box
      id={props.slug}
      className="leancloud_visitors"
      ml={1}
      display="flex"
      flexWrap="wrap"
      alignItems="center"
    >
      <FontAwesomeIcon icon={faEye} size="sm"></FontAwesomeIcon>
      <Box ml={0.5}>已被阅读</Box>
      <Box>{count || 0}</Box>
      <Box>次</Box>
    </Box>
  )
}
