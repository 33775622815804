import { faEye, faTag } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  createStyles,
  fade,
  makeStyles,
  StandardProps,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { BlogNodeData } from '../../@data/blog'
import { formatDate, formatDateTime } from '../../utils/datetime'
import { getCategoryPath } from '../../utils/paths'
import DraftFlag from '../blog/DraftFlag'
import TagList from '../blog/TagList'
import { PageReaderCounter } from '../stats/PageReaderCounter'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transition: 'transform .3s ease-in, box-shadow .3s ease-in',
      willChange: 'transform,box-shadow',
    },
    hoverRoot: {
      boxShadow: `0 14px 26px -12px ${fade(theme.palette.common.black, 0.42)},
          0 4px 23px 0px rgba(0, 0, 0, 0.12),
          0 8px 10px -5px ${fade(theme.palette.common.black, 0.2)}`,
      transform: 'scale(1.02)',
      transition: 'transform .3s ease-out, box-shadow .3s ease-out',
      willChange: 'transform,box-shadow',
    },
    hoverTitle: {
      textDecoration: 'underline',
    },
    viewIcon: {
      opacity: 0,
      transition: 'opacity .3s ease-out',
    },
    hoverViewIcon: {
      opacity: 1,
      transition: 'opacity .3s ease-out',
    },
  })
)
interface Props {
  blog: BlogNodeData
}
interface State {
  interested: boolean
}
export default function BlogCard(props: Props & StandardProps<CardProps, ''>) {
  const [state, setState] = useState<State>({ interested: false })
  const classes = useStyles()
  const { excerpt, frontmatter, fields } = props.blog
  return (
    <Card
      className={state.interested ? classes.hoverRoot : classes.root}
      onMouseOver={() => setState({ interested: true })}
      onMouseLeave={() => setState({ interested: false })}
    >
      <CardHeader
        title={
          <Link to={fields.slug}>
            <Box display="flex" alignItems="baseline">
              <Typography variant="h6">{frontmatter.title || fields.slug}</Typography>
              {frontmatter.draft ? (
                <Box ml={2}>
                  <DraftFlag />
                </Box>
              ) : null}
              <Box ml={2} style={{ opacity: state.interested ? 1 : 0 }}>
                <FontAwesomeIcon
                  icon={faEye}
                  size="xs"
                  className={state.interested ? classes.hoverViewIcon : classes.viewIcon}
                />
              </Box>
            </Box>
          </Link>
        }
        subheader={
          <Box display="flex" flexDirection="column" alignItems="baseline">
            <Typography variant="caption" style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Tooltip title={formatDateTime(frontmatter.date)}>
                <Box component="span">{formatDate(frontmatter.date)}</Box>
              </Tooltip>
              <Box component="span" ml={1}>
                {frontmatter.category ? (
                  <Link to={getCategoryPath(frontmatter.category, fields.sub)}>
                    {frontmatter.category || ''}
                  </Link>
                ) : null}
              </Box>
              <PageReaderCounter slug={fields.slug} />
            </Typography>
          </Box>
        }
        classes={{ title: state.interested ? classes.hoverTitle : '' }}
      />
      <CardContent>
        <Typography variant="caption" color="textSecondary">
          {frontmatter.description || excerpt}
        </Typography>
      </CardContent>
      <CardActions>
        <Box px={1}>
          <FontAwesomeIcon icon={faTag} />
        </Box>
        <TagList tags={frontmatter.tags || []} sub={fields.sub} />
      </CardActions>
    </Card>
  )
}
