import { configureStore, createAction, createReducer, getDefaultMiddleware } from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export enum ThemeType {
  Light,
  Dark,
}

interface ThemeState {
  theme: ThemeType
}

export const switchTheme = createAction<ThemeType>('theme/switch')

const themeReducer = createReducer<ThemeState>({ theme: ThemeType.Light }, builder =>
  builder.addCase(switchTheme, (_, action) => ({ theme: action.payload }))
)

export type ReduxState = ThemeState

const rootReducer = themeReducer

const persistedReducer = persistReducer(
  {
    key: '0x1c.dev',
    storage,
  },
  rootReducer
)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    // Suppress an error caused by redux-persist,
    // that non-serializable actions are detected
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})
const persistor = persistStore(store)

export default {
  store,
  persistor,
}
