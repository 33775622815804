import { faBookmark } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { List, ListItem, ListItemIcon, ListItemText, Theme, useTheme } from '@material-ui/core'
import { Link } from 'gatsby'
import React from 'react'
import { CategoryModels } from '../../@data/blog'
import { getCategoryPath } from '../../utils/paths'

interface Props {
  categories: CategoryModels
  sub: string
}

export default function CategoryMenu(props: Props): JSX.Element {
  const theme = useTheme<Theme>()
  return (
    <List component="nav" aria-label="categories">
      {props.categories.map(({ name }) => (
        <ListItem
          button
          component={Link}
          key={name}
          disableGutters
          to={getCategoryPath(name, props.sub)}
        >
          <ListItemIcon
            style={{
              minWidth: 0,
              marginLeft: theme.spacing() * 0.5,
              marginRight: theme.spacing() * 1.3,
            }}
          >
            <FontAwesomeIcon icon={faBookmark} />
          </ListItemIcon>
          <ListItemText
            primary={name}
            style={{ fontSize: theme.typography.caption.fontSize }}
            disableTypography={true}
          />
        </ListItem>
      ))}
    </List>
  )
}
