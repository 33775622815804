import { createMuiTheme, fade } from '@material-ui/core/styles'
import { HeaderOptions, NavOptions } from '@material-ui/core/styles/createMuiTheme'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import { TypographyOptions } from '@material-ui/core/styles/createTypography'
import { Overrides } from '@material-ui/core/styles/overrides'
import merge from 'lodash/merge'
import './iron-fire-light-prismjs.scss'

const themeName = 'Iron Fire Light'

const bodyFontFamily = [
  '-apple-system',
  'BlinkMacSystemFont',
  'Fira Sans',
  'Roboto',
  'Helvetica Neue',
  'Helvetica',
  'Tahoma',
  'Arial',
  'Microsoft YaHei',
  '微软雅黑',
  'Source Han Sans SC',
  'Noto Sans CJK SC',
  'WenQuanYi Micro Hei',
  'STXihei',
  '华文细黑',
  'Heiti',
  '黑体',
  'SimSun',
  '宋体',
  'sans',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
].join(',')
const headerFontFamily = bodyFontFamily

const codeFontFamily = [
  'Fira Code',
  'Menlo',
  'Monaco',
  'Consolas',
  'Lucida Console',
  'Courier New',
  'Courier',
  'monospace',
].join(',')

const palette: PaletteOptions = {
  common: { white: '#ffffff', black: '#333333' },
  primary: { main: '#3A9FAA', light: '#7AACAC', dark: '#3D6A70', contrastText: '#ffffff' },
  secondary: { main: '#FABB00', light: '#F8D078', dark: '#AF7C76', contrastText: '#444' },
}
const nav: NavOptions = {
  normalColor: '#333333',
  hoverColor: '#C18A84',
  activeColor: '#AF7C76',
}
const header: HeaderOptions = {
  background: '#FAFAFA',
}
const typography: TypographyOptions = {
  fontFamily: bodyFontFamily,
  fontSize: 20,
  htmlFontSize: 20,
  body1: { fontWeight: 400 },
  h1: { fontSize: '2.75rem', fontWeight: 700, fontFamily: headerFontFamily },
  h2: { fontSize: '2.5rem', fontWeight: 700, fontFamily: headerFontFamily },
  h3: { fontSize: '2.25rem', fontWeight: 700, fontFamily: headerFontFamily },
  h4: { fontSize: '2rem', fontWeight: 400, fontFamily: headerFontFamily },
  h5: { fontSize: '1.75rem', fontWeight: 400, fontFamily: headerFontFamily },
  h6: { fontSize: '1.5rem', fontWeight: 400, fontFamily: headerFontFamily },
}

const theme = createMuiTheme({
  name: themeName,
  palette,
  typography,
  spacing: 10,
  nav,
  header,
})

// Merge overrides based on generated theme
merge(theme, {
  overrides: {
    MuiCard: {
      root: {
        boxShadow: `0 2px 16px -8px ${fade(theme.palette.common.black, 0.24)},
      0 3px 10px -3px ${fade(theme.palette.common.black, 0.2)},
      0 4px 10px 0 ${fade(theme.palette.common.black, 0.12)}`,
      },
    },
    MuiTooltip: {
      tooltip: {
        background: `${theme.palette.secondary.main} !important`,
        color: theme.palette.secondary.contrastText,
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: { fontFamily: typography.fontFamily, color: theme.palette.common.black },
        a: {
          color: theme.palette.primary.main,
          textDecoration: 'none',
          transition: 'color .3s ease-out',
        },
        'a:not(.MuiButton-root):not(.MuiListItem-root):not(.MuiChip-root):hover': {
          color: theme.palette.secondary.main,
          textDecoration: 'underline',
        },
        code: {
          fontFamily: codeFontFamily,
          color: theme.palette.secondary.dark,
        },
        blockquote: {
          boxSizing: 'border-box',
          margin: 0,
          padding: '.5em 1em .5em 1em',
          borderLeft: `5px solid ${fade(theme.palette.primary.light, 0.7)}`,
          background: `${fade(theme.palette.primary.light, 0.1)}`,
        },
        hr: {
          border: 'none',
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
        em: {
          margin: '0 .2em',
        },
        ol: {
          paddingLeft: theme.spacing() * 2.5,
        },
      },
    },
  } as Overrides,
})
export default theme
