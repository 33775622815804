import { faEye } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Tooltip, Typography } from '@material-ui/core'
import React from 'react'
import { BlogNodeData } from '../../@data/blog'
import { formatDate, formatDateTime } from '../../utils/datetime'
import { getCategoryPath } from '../../utils/paths'
import GaLink from '../common/GaLink'
import DraftFlag from './DraftFlag'
import TagList from './TagList'

interface Props {
  blog: BlogNodeData
}

export default function BlogHeader({ blog: mdx }: Props) {
  const title = mdx.frontmatter.title || mdx.fields.slug
  return (
    <React.Fragment>
      <Typography id="post-title" variant="h3">
        {title}
      </Typography>
      <Box my={1}>
        <Typography variant="subtitle2" color="textSecondary">
          <Box display="flex" flexWrap="wrap">
            <Tooltip title={formatDateTime(mdx.frontmatter.date)}>
              <Box component="span" mr={1}>
                {formatDate(mdx.frontmatter.date)}
              </Box>
            </Tooltip>
            <GaLink to={getCategoryPath(mdx.frontmatter.category, mdx.fields.sub)}>
              {mdx.frontmatter.category}
            </GaLink>
            <Box
              id={mdx.fields.slug}
              className="leancloud_visitors"
              data-flag-title={title}
              ml={1}
              display="flex"
              flexWrap="wrap"
              alignItems="center"
            >
              <FontAwesomeIcon icon={faEye} size="sm"></FontAwesomeIcon>
              <Box ml={0.5}>该文章已被阅读</Box>
              <Box className="leancloud-visitors-count" />
              <Box>次</Box>
            </Box>
            {mdx.frontmatter.draft ? (
              <Box ml={2} component="span">
                <DraftFlag />
              </Box>
            ) : null}
          </Box>
        </Typography>
      </Box>
      <Box mb={1} display="flex" flexWrap="wrap">
        <TagList tags={mdx.frontmatter.tags} sub={mdx.fields.sub} />
      </Box>
    </React.Fragment>
  )
}
