import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import React, { DetailedHTMLProps, HTMLAttributes, PropsWithChildren, ReactElement } from 'react'
import { clampProgress, masterId, ScrollContext, ScrollState } from './common'
import ScrollSlave, { SlaveProps } from './ScrollSlave'
gsap.registerPlugin(ScrollToPlugin)

interface Props {
  pages: number
}

type PropsType = PropsWithChildren<Props> &
  Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'children'>

interface StopInfo {
  id: string
  pos: number
}

interface State {
  stopIndex: number
  stops: StopInfo[]
  immediate: boolean
}

type StateType = ScrollState & State

export default class ScrollMaster extends React.Component<PropsType, StateType> {
  private _wrapperRef = React.createRef<HTMLDivElement>()
  private _scrollTween: gsap.core.Tween | null = null
  private _handleScroll = false
  constructor(props: PropsType) {
    super(props)

    this._updateMasterHeight = this._updateMasterHeight.bind(this)
    this._onScroll = this._onScroll.bind(this)
    this._onMasterClick = this._onMasterClick.bind(this)
    this._onScrollInterrupt = this._onScrollInterrupt.bind(this)

    const stops = this._calcStops()
    const anchor = this._getAnchor()
    const stopIndex = anchor ? stops.findIndex(stop => stop.id === anchor) : 0

    this.state = {
      viewportHeight: this._wndHeight,
      totalHeight: this._wndHeight,
      scrollHeight: 0,
      scrollDistance: 0,
      progress: 0,
      stopIndex,
      stops,
      immediate: false,
    }
  }

  componentDidMount() {
    document.addEventListener('resize', this._updateMasterHeight)
    document.addEventListener('scroll', this._onScroll)
    window.addEventListener('wheel', this._onScrollInterrupt)
    window.addEventListener('touchmove', this._onScrollInterrupt)
    this._updateMasterHeight()
    this._setAnchor(this.state.stopIndex, true)
    this._handleScroll = true
    requestAnimationFrame(() => {
      if (this.state.stops.length > 0) {
        this._scrollTo(this.state.stops[this.state.stopIndex], this.state.immediate)
      }
    })
  }
  componentWillUnmount() {
    if (this._scrollTween) {
      this._scrollTween.kill()
    }
    this._handleScroll = false
    document.removeEventListener('resize', this._updateMasterHeight)
    document.removeEventListener('scroll', this._onScroll)
    window.removeEventListener('wheel', this._onScrollInterrupt)
    window.removeEventListener('touchmove', this._onScrollInterrupt)
  }
  componentDidUpdate(prevProps: PropsType, prevState: StateType) {
    if (prevProps.children !== this.props.children) {
      this.setState({ stops: this._calcStops() })
    }
    if (this._isSsr) {
      return
    }
    if (this._handleScroll) {
      const histoIndex = history.state?.stopIndex
      const prevIndex = prevState.stopIndex
      const currentIndex = this.state.stopIndex
      if (histoIndex != null && histoIndex !== currentIndex) {
        this.setState({ stopIndex: histoIndex, immediate: true })
      } else if (prevIndex !== currentIndex || this._scrollTween == null) {
        const { stopIndex, stops } = this.state
        const stop = stopIndex < stops.length && stopIndex >= 0 ? stops[stopIndex] : null
        this._scrollTo(stop, this.state.immediate)
      }
    }
  }

  render() {
    const { children, style, ...props } = this.props
    return (
      <div
        id={masterId}
        ref={this._wrapperRef}
        style={{
          position: 'relative',
          width: '100%',
          height: this.state.totalHeight,
          ...style,
        }}
        onClick={this._onMasterClick}
        {...props}
      >
        <div style={{ pointerEvents: 'none' }}>
          <ScrollContext.Provider value={this.state}>{children}</ScrollContext.Provider>
        </div>
      </div>
    )
  }

  private get _isSsr() {
    return typeof window === 'undefined'
  }
  private get _wndHeight() {
    return this._isSsr ? 500 : window.innerHeight
  }
  private _updateMasterHeight() {
    this._onScroll()
  }

  private _calcStops(): StopInfo[] {
    const stops: StopInfo[] = []
    if (this.props.children) {
      React.Children.forEach(this.props.children, (child, index) => {
        const comp = child as ReactElement
        if (comp && comp.type === ScrollSlave) {
          const slaveProps = comp.props as SlaveProps<any>
          let { start, end } = slaveProps
          const stopAt = slaveProps.stopAt
          if (stopAt != null) {
            start = start == null ? 0 : start
            end = end == null ? 1 : end
            const pos = start + (end - start) * stopAt
            stops.push({ id: slaveProps.id || `${index}`, pos })
          }
        }
      })
    }
    if (stops.length > 0 && stops[stops.length - 1].pos < 1) {
      stops.push({ id: 'eof', pos: 1 })
    }
    return stops
  }
  private _scrollTo(stop: StopInfo | null, immediate: boolean) {
    if (this._isSsr) {
      return
    }

    if (!stop) {
      stop = this.state.stops[0]
    }
    const y = stop.pos * this.state.scrollHeight
    if (this._scrollTween) {
      this._scrollTween.kill()
    }
    if (immediate) {
      requestAnimationFrame(() => window.scrollTo({ top: y }))
    } else {
      this._scrollTween = gsap
        .to(window, { duration: 1, scrollTo: y, ease: 'sine.out' })
        .eventCallback('onComplete', () => {
          this._scrollTween = null
        })
    }
  }
  private _setAnchor(stopIndex: number, replace: boolean) {
    if (this._isSsr) {
      return
    }
    const currentUrl = location.href
    const hashIndex = currentUrl.indexOf('#')
    let baseUrl: string
    if (hashIndex < 0) {
      baseUrl = currentUrl
    } else {
      baseUrl = currentUrl.substring(0, hashIndex)
    }
    const stop = this.state.stops[stopIndex]
    const newUrl = `${baseUrl}#${stop.id}`
    if (replace) {
      history.replaceState({ stopIndex }, document.title, newUrl)
    } else {
      history.pushState({ stopIndex, scrollY: window.scrollY }, document.title, newUrl)
    }
  }
  private _getAnchor() {
    if (this._isSsr) {
      return ''
    }
    const currentUrl = location.href
    const hashIndex = currentUrl.indexOf('#')
    if (hashIndex < 0 || hashIndex === currentUrl.length - 1) {
      return ''
    } else {
      return currentUrl.substring(hashIndex + 1)
    }
  }
  private _onScroll() {
    const scrollY = this._isSsr ? 0 : window.scrollY
    const h = document.body.scrollHeight - this._wndHeight
    let progress = clampProgress(h === 0 ? 0 : scrollY / h)
    if (1 - progress < 0.01) {
      progress = 1
    }
    const pageHeight = this._wndHeight
    const totalHeight = Math.round(pageHeight * this.props.pages)
    const scrollHeight = totalHeight - pageHeight
    // console.log('h', h, 'y', scrollY, 'progress', progress)
    this.setState({
      viewportHeight: pageHeight,
      totalHeight,
      scrollHeight,
      scrollDistance: scrollY,
      progress: progress,
    })
  }

  private _onScrollInterrupt() {
    if (this._scrollTween) {
      this._scrollTween.kill()
    }
  }

  private _onMasterClick() {
    let stopIndex = this.state.stops.findIndex(stop => stop.pos > this.state.progress + 0.001)
    if (stopIndex < 0) {
      stopIndex = 0
    }
    this._setAnchor(stopIndex, false)
    if (this._scrollTween != null) {
      this._scrollTween.kill()
      this._scrollTween = null
    }
    this.setState({ stopIndex, immediate: false })
  }
}
