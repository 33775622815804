import { faLink } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fade, Theme } from '@material-ui/core'
import { createStyles, makeStyles, useTheme } from '@material-ui/styles'
import React from 'react'

type Sizes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

interface Props {
  size: Sizes
  id?: string
}

const sizeRegex = /^([.\d]+)(\w+)$/
const headerAdjust = 0.7

const useStyles = (size: Sizes) =>
  makeStyles((theme: Theme) => {
    const color = theme.palette.text.primary
    const rawFontSize = theme.typography[size].fontSize
    let fontSize = rawFontSize
    if (typeof rawFontSize === 'number') {
      fontSize = rawFontSize * headerAdjust
    } else if (rawFontSize) {
      const matches = sizeRegex.exec(`${rawFontSize}`)
      if (matches && matches.length > 1) {
        const v = +matches[1] * headerAdjust
        const u = matches.length > 2 ? matches[2] : ''
        fontSize = `${v}${u}`
      }
    }
    return createStyles({
      link: {
        color: color,
        fontSize: fontSize,
        textShadow: `0 0 10px ${fade(theme.palette.primary.dark, 0.2)}`,
        textDecoration: 'none !important',
        paddingBottom: 1,
        '&:hover': {
          color: color + ' !important',
          borderBottom: `1px solid ${theme.palette.secondary.dark}`,
          paddingBottom: 0,
        },
      },
    })
  })

export default function Heading({
  size,
  children,
  ...props
}: Props & React.Props<any>): JSX.Element {
  const theme = useTheme<Theme>()
  const classes = useStyles(size)()
  const wrapper = (
    <div style={{ display: 'flex', alignItems: 'center' }} className="md-heading-anchor">
      <a href={`#${props.id}`} className={['md-heading-anchor-link', classes.link].join(' ')}>
        {children}
        <FontAwesomeIcon
          icon={faLink}
          color={theme.palette.action.disabled}
          className="md-heading-anchor-icon"
        />
      </a>
    </div>
  )
  switch (size) {
    case 'h1':
      return <h1 {...props}>{wrapper}</h1>
    case 'h2':
      return <h2 {...props}>{wrapper}</h2>
    case 'h3':
      return <h3 {...props}>{wrapper}</h3>
    case 'h4':
      return <h4 {...props}>{wrapper}</h4>
    case 'h5':
      return <h5 {...props}>{wrapper}</h5>
    case 'h6':
      return <h6 {...props}>{wrapper}</h6>
    default:
      return <div {...props}>{wrapper}</div>
  }
}
