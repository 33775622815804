import AV from 'leancloud-storage'
import 'prismjs/plugins/command-line/prism-command-line.css'
import './src/styles/code-highlight.scss'
import './src/styles/global.scss'
import './src/styles/valine.scss'

export { wrapRootElement } from './src/components/frame/wrapRootElement'

export function onRouteUpdate() {
  if (typeof window !== 'undefined') {
    if (!AV.applicationId) {
      AV.init({
        appId: window.valineOptions.appId || '',
        appKey: window.valineOptions.appKey || '',
        serverURLs: window.valineOptions.serverURLs || '',
      })
    }
  }
}
