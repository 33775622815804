import { Typography } from '@material-ui/core'
import { graphql, PageProps } from 'gatsby'
import kebabCase from 'lodash/kebabCase'
import React from 'react'
import GaLink from '../components/common/GaLink'
import SEO from '../components/common/SEO'
import Layout from '../components/frame/Layout'

interface Props {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMdx: {
    group: {
      fieldValue: string
      totalCount: number
    }[]
  }
}

export default function TagsIndex({ data, location }: PageProps<Props>) {
  const { title } = data.site.siteMetadata
  const { group } = data.allMdx
  return (
    <Layout location={location} title={title}>
      <SEO title="标签列表"></SEO>
      <Typography variant="h1">Tags</Typography>
      {group.map(tag => (
        <GaLink to={`/tags/${kebabCase(tag.fieldValue)}`} key={`tag-${tag.fieldValue}`}>
          {tag.fieldValue} ({tag.totalCount})
        </GaLink>
      ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
