import React from 'react'

export interface ScrollState {
  viewportHeight: number
  totalHeight: number
  scrollHeight: number
  scrollDistance: number
  progress: number
}
export const ScrollContext = React.createContext<ScrollState>({
  viewportHeight: 0,
  totalHeight: 0,
  scrollHeight: 0,
  scrollDistance: 0,
  progress: 0,
})
export const masterId = 'scroll-master'

export function clampProgress(v: number): number {
  return Math.min(1, Math.max(0, v))
}

export type AnimationUpdater = (state: ScrollState, target: HTMLElement | null) => void
