import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import aboutStyles from './about.module.scss'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    links: {
      gridColumn: 1,
    },
    skillset: {
      gridColumn: -2,
    },
  })
)

interface Props {
  type: 'links' | 'skillSet'
  titleIcon: IconDefinition
  title: string
  items: React.ReactNode[]
  className?: string
}

export default function AboutDetailList(props: Props) {
  const classes = useStyles()
  return (
    <section
      className={[classes.section, aboutStyles.detail, props.className].join(' ')}
      data-type={props.type}
    >
      <summary>
        <FontAwesomeIcon icon={props.titleIcon} />
        <span>{props.title}</span>
      </summary>
      <ul>
        {props.items.map(item => (
          <li key={typeof item === 'string' ? item : Math.random().toString()}>{item}</li>
        ))}
      </ul>
    </section>
  )
}
