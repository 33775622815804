// Gatsby supports TypeScript natively!
import { graphql, PageProps } from 'gatsby'
import gsap from 'gsap'
import React from 'react'
import SEO from '../components/common/SEO'
import Root from '../components/frame/Root'
import AboutPage from '../components/home/AboutPage'
import BitsPage from '../components/home/BitsPage'
import DevPage from '../components/home/DevPage'
import LandingPage from '../components/home/LandingPage'
import ScrollMaster from '../components/scrollMaster/ScrollMaster'
import ScrollSlave from '../components/scrollMaster/ScrollSlave'

type Data = {
  site: {
    buildTime: string
    siteMetadata: {
      title: string
      description: string
      social: { twitter: string; github: string }
    }
  }
}

class Home extends React.Component<PageProps<Data>> {
  private _maskRef = React.createRef<HTMLDivElement>()
  private _bgAnim: gsap.core.Timeline | null = null
  constructor(props: PageProps<Data>) {
    super(props)
  }
  componentWillUnmount() {
    this._bgAnim?.kill()
    this._bgAnim = null
  }
  componentDidMount() {
    gsap.fromTo(this._maskRef.current, 0.5, { opacity: 1 }, { opacity: 0 })
  }
  render() {
    const { title, description } = this.props.data.site.siteMetadata
    return (
      <Root>
        <SEO title={title} description={description} />
        <div
          ref={this._maskRef}
          style={{
            position: 'fixed',
            top: 0,
            width: '100vw',
            height: '100vh',
            zIndex: 999,
            background: 'linear-gradient(to bottom, #3D6A70,#1C5259)',
            pointerEvents: 'none',
          }}
        ></div>
        <ScrollMaster pages={5}>
          <ScrollSlave
            style={{
              background: 'linear-gradient(to bottom, #3D6A70,#1C5259)',
            }}
            onUpdate={(state, target) => {
              if (!this._bgAnim) {
                this._bgAnim = gsap
                  .timeline()
                  .set(target, { background: 'linear-gradient(-135deg, #218284,#114C4C)' })
                  .to(target, 0.1, {
                    background: 'linear-gradient(45deg, #0066F9, #0040A7)',
                    delay: 0.1,
                    ease: 'power2.inOut',
                  })
                  .to(target, 0.15, {
                    background: 'linear-gradient(-135deg, #FFCC5A, #BF851F)',
                    delay: 0.1,
                    ease: 'power2.inOut',
                  })
                  .to(target, 0.2, {
                    background: 'linear-gradient(45deg, #EF7C73, #C4574E)',
                    delay: 0,
                    ease: 'power2.inOut',
                  })
                  .pause()
              }
              this._bgAnim.progress(state.progress)
            }}
          >
            {(state, ref) => (
              <div
                ref={ref}
                style={{ position: 'fixed', top: 0, width: '100%', height: '100vh' }}
              />
            )}
          </ScrollSlave>
          <ScrollSlave id="landing" start={0} end={0.2} stopAt={0.5}>
            {state => {
              return <LandingPage progress={state.progress} />
            }}
          </ScrollSlave>
          <ScrollSlave id="dev" start={0.2} end={0.5} stopAt={0.28}>
            {state => <DevPage progress={state.progress} />}
          </ScrollSlave>
          <ScrollSlave id="bits" start={0.55} end={0.8} stopAt={0.5}>
            {state => <BitsPage progress={state.progress} />}
          </ScrollSlave>
          <ScrollSlave id="about" start={0.8} end={1} stopAt={1}>
            {state => <AboutPage progress={state.progress} />}
          </ScrollSlave>
        </ScrollMaster>
      </Root>
    )
  }
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      buildTime
      siteMetadata {
        title
        description
        social {
          twitter
          github
        }
      }
    }
  }
`
