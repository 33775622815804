import { faChevronDoubleDown } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Typography } from '@material-ui/core'
import { graphql, useStaticQuery } from 'gatsby'
import gsap from 'gsap'
import React, { useEffect, useRef } from 'react'
import { AnimatedPageProps } from './common'

interface PageData {
  site: { siteMetadata: { title: string; description: string } }
  logo: { publicURL: string }
}

export default function LandingPage(props: AnimatedPageProps) {
  const { site, logo } = useStaticQuery<PageData>(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
      logo: file(absolutePath: { regex: "/logo-alone.svg$/" }) {
        publicURL
      }
    }
  `)
  const logoRef = useRef(null)
  const titleRef = useRef(null)
  const subTitleRef = useRef(null)
  const hintRef = useRef(null)
  const arrowRef = useRef(null)
  useEffect(() => {
    const logo = logoRef.current
    const title = titleRef.current
    const subTitle = subTitleRef.current
    const hint = hintRef.current
    const arrow = arrowRef.current
    const anim = gsap
      .timeline()
      .fromTo(logo, 1, { opacity: 1, scale: 1.2, y: '0%' }, { opacity: 1, scale: 1, y: '0%' })
      .fromTo(
        [title, subTitle, hint],
        1,
        { y: '100%', opacity: 0 },
        { y: '0%', opacity: 1, stagger: 0.3, ease: 'power2.out' }
      )
      .to(subTitle, 1, {})
      .to([logo, title, subTitle], 1, { opacity: 0, y: '-100px', stagger: 0.3 }, 'out')
      .fromTo(hint, 1, { scale: 1 }, { scale: 0 }, 'out')
      .pause()
      .progress(props.progress)
    const arrowAnim = gsap.fromTo(
      arrow,
      0.5,
      { y: '0%' },
      { y: '30%', repeat: -1, yoyo: true, ease: 'sine.inOut' }
    )
    return () => {
      anim.kill()
      arrowAnim.kill()
    }
  }, [props.progress])
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: '100vh',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '2rem 2rem 4rem 2rem',
      }}
    >
      <img src={logo.publicURL} ref={logoRef} alt="logo" height="60%" />
      <Box mt={3} display="flex" flexDirection="column" alignItems="center">
        <Typography ref={titleRef} variant="h1">
          {site.siteMetadata.title}
        </Typography>
        <Typography ref={subTitleRef} variant="subtitle1">
          {site.siteMetadata.description}
        </Typography>
        <div
          ref={hintRef}
          style={{
            marginTop: '3rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="caption">TAP TO START</Typography>
          <div ref={arrowRef}>
            <FontAwesomeIcon icon={faChevronDoubleDown} style={{ marginTop: '.5em' }} />
          </div>
        </div>
      </Box>
    </div>
  )
}
