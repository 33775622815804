import { MDXProvider } from '@mdx-js/react'
import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import reduxStore from '../../@data/redux/store'
import Gist from '../blog/Gist'
import Loading from '../common/Loading'

const components = {
  Gist,
}
export const wrapRootElement = ({ element }: any) => {
  return (
    <Provider store={reduxStore.store}>
      <PersistGate loading={<Loading />} persistor={reduxStore.persistor}>
        <MDXProvider components={components}>{element}</MDXProvider>
      </PersistGate>
    </Provider>
  )
}
