import { faGithub, faLinkedinIn, faTelegram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import {
  faAlien,
  faEnvelope,
  faGamepad,
  faGlobe,
  faRedo,
  faRss,
  faServer,
  faTablet,
} from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, fade, Theme, Tooltip, Typography } from '@material-ui/core'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import gsap from 'gsap'
import React, { useEffect, useRef } from 'react'
import AboutDetailList from './AboutDetailList'
import {
  AnimatedPageProps,
  IntroClickEvent,
  PageArticle,
  suppressIntroBgClick,
  useIntroStyles,
} from './common'

const useStyles = useIntroStyles({
  titleColor: fade('#FFDCDC', 0.7),
  articleColor: 'white',
  buttonColor: 'white',
  extra: (theme: Theme) => ({
    root: {
      // display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'flex-start',
      // alignItems: 'center',
    },
    inner: {
      margin: '0 auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        width: '80%',
      },
      '&>*': {
        flex: '0 0 auto',
      },
    },
    title: {
      position: 'unset',
      alignItems: 'center',
      left: '0 !important',
      right: '0 !important',
    },
    article: {
      position: 'unset',
      top: '0 !important',
      left: '0 !important',
      right: '0 !important',
      maxWidth: '100%',
      flex: '0 0 auto',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    content: {
      paddingTop: '2rem',
      paddingBottom: '2rem',
      textAlign: 'center',
    },
    detailGrid: {
      color: 'white',
      display: 'grid',
      gridTemplateColumns: 'repeat(2,50%)',
      gridAutoFlow: 'column dense',
      gridGap: '.5em',
      '& [data-type=links]': {
        gridColumn: 1,
      },
      '& [data-type=skillSet]': {
        gridColumn: 2,
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '100%',
        '& [data-type=skillSet]': {
          gridColumn: 1,
        },
      },
      '& section': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      '& a': {
        color: 'white',
      },
      '&.active': {
        pointerEvents: 'all',
      },
    },
    buttonBar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: '1rem',
      '&>*': {
        margin: 0,
      },
    },
    hint: {
      display: 'flex',
      alignItems: 'baseline',
      color: 'white',
      bottom: '2rem',
      marginLeft: '2rem',
      [theme.breakpoints.down('sm')]: {
        left: 0,
        right: 0,
      },
      '& svg': {
        marginLeft: '.5em',
      },
    },
  }),
})

interface SiteData {
  site: {
    siteMetadata: {
      author: { email: string }
      social: { telegram: string; github: string; twitter: string; linkedin: string }
    }
  }
}

export default function AboutPage(props: AnimatedPageProps) {
  const { mdx, site } = useStaticQuery<PageArticle & SiteData>(graphql`
    {
      mdx(fields: { slug: { eq: "/intro/about/" } }) {
        frontmatter {
          title
        }
        body
      }
      site {
        siteMetadata {
          author {
            email
          }
          social {
            twitter
            github
            telegram
            linkedin
          }
        }
      }
    }
  `)
  const { email } = site.siteMetadata.author
  const { telegram, github, twitter, linkedin } = site.siteMetadata.social
  const rootRef = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLDivElement>(null)
  const titleRef = useRef<HTMLDivElement>(null)
  const articleRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const hintRef = useRef(null)
  useEffect(() => {
    const root = rootRef.current
    const inner = innerRef.current
    const innerScrollHeight = Math.max(
      0,
      (inner ? inner.clientHeight + 48 * 2 : 0) - window.innerHeight
    )
    const anim = gsap
      .timeline()
      .fromTo(root, 1, { y: '50%', opacity: 0 }, { y: '0%', opacity: 1 })
      .fromTo(inner, 1, { y: 0 }, { y: `-${innerScrollHeight}px` })
      .to(root, 1, {})
      .pause()
      .progress(props.progress)
    return () => {
      anim.kill()
    }
  })
  const classes = useStyles()
  return (
    <div ref={rootRef} className={classes.root} style={{ opacity: 0 }}>
      <div ref={innerRef} className={classes.inner}>
        <div className={classes.title} ref={titleRef}>
          <Typography variant="h2">{mdx.frontmatter.title}</Typography>
        </div>
        <div className={classes.article} ref={articleRef}>
          <div className={[classes.content].join(' ')} ref={contentRef}>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </div>
        </div>
        <div className={[classes.detailGrid, props.progress > 0.6 ? 'active' : ''].join(' ')}>
          <AboutDetailList
            type="links"
            titleIcon={faAlien}
            title="联系我"
            items={[
              <Tooltip title="电子邮件" key="email">
                <a
                  href={`mailto:${email}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={suppressIntroBgClick}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              </Tooltip>,
              <Tooltip title="Telegram" key="telegram">
                <a
                  href={`https://t.me/${telegram}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={suppressIntroBgClick}
                >
                  <FontAwesomeIcon icon={faTelegram} />
                </a>
              </Tooltip>,
              <Tooltip title="GitHub" key="github">
                <a
                  href={`https://github.com/${github}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={suppressIntroBgClick}
                >
                  <FontAwesomeIcon icon={faGithub} />
                </a>
              </Tooltip>,
              <Tooltip title="LinkedIn" key="linkedin">
                <a
                  href={`https://linkedin.com/in/${linkedin}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={suppressIntroBgClick}
                >
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
              </Tooltip>,
              <Tooltip title="Twitter" key="twitter">
                <a
                  href={`https://twitter.com/${twitter}`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={suppressIntroBgClick}
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </Tooltip>,
            ]}
          />
          <AboutDetailList
            type="links"
            titleIcon={faGlobe}
            title="关注本站"
            items={[
              <Tooltip title="RSS" key="rss">
                <a
                  href={`/rss.xml`}
                  target="_blank"
                  rel="noreferrer"
                  onClick={suppressIntroBgClick}
                >
                  <FontAwesomeIcon icon={faRss} />
                </a>
              </Tooltip>,
            ]}
          />
          <AboutDetailList
            type="skillSet"
            titleIcon={faTablet}
            title="前端"
            items={['React', 'Angular', 'Electron', 'Vanilla JS', 'Flutter']}
          />
          <AboutDetailList
            type="skillSet"
            titleIcon={faServer}
            title="后端"
            items={['Golang', '.NET', 'Python']}
          />
          <AboutDetailList
            type="skillSet"
            titleIcon={faGamepad}
            title="游戏"
            items={['Unity3D', 'Cocos Creator', 'Godot']}
          />
        </div>
        <div className={classes.buttonBar}>
          <Button
            component={Link}
            to="/about"
            variant="outlined"
            classes={{ root: classes.button, label: classes.buttonLabel }}
            className={props.progress > 0.6 ? 'active' : ''}
            onClick={(e: IntroClickEvent) => {
              // window.scrollTo({ top: 0 })
              suppressIntroBgClick(e)
            }}
          >
            查看更多
          </Button>
          <div ref={hintRef} className={classes.hint}>
            <Typography variant="caption">OR TAP TO REPLAY!</Typography>
            <div>
              <FontAwesomeIcon icon={faRedo} style={{ marginTop: '.5em' }} spin />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
