/* eslint-disable react/display-name */

import {
  Box,
  Card,
  CardContent,
  createStyles,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Theme,
} from '@material-ui/core'
import { MDXProvider } from '@mdx-js/react'
import { graphql, PageProps } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React, { useEffect } from 'react'
import { scroller, scrollSpy } from 'react-scroll'
import { BlogNodeData } from '../@data/blog'
import BlogHeader from '../components/blog/BlogHeader'
import BlogNavButton, { BlogNav } from '../components/blog/BlogNavButton'
import Comment from '../components/blog/Comment'
import Heading from '../components/blog/Heading'
import TOC from '../components/blog/TOC'
import TOCDrawer from '../components/blog/TOCDrawer'
import Bio from '../components/common/Bio'
import SEO from '../components/common/SEO'
import Layout from '../components/frame/Layout'

interface Data {
  site: {
    siteMetadata: {
      title: string
    }
  }
  mdx: BlogNodeData
}
interface Context {
  previous: BlogNav
  next: BlogNav
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing() * 3,
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing() * 1.5,
      },
    },
    navBar: {
      justifyContent: 'space-between',
    },
  })
)

const tocSideBar = {
  width: '222px',
  stickyTop: '60px',
}

const BlogPostTemplate = ({ data, pageContext, location }: PageProps<Data, Context>) => {
  const siteTitle = data.site.siteMetadata.title
  const { frontmatter, body, excerpt, fields, tableOfContents } = data.mdx
  const { title, description } = frontmatter
  const { previous, next } = pageContext
  const classes = useStyles()

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }
    scrollSpy.update()

    // Scroll to URL anchor or title on loaded
    const parts = location.href.split('#')
    const scrollTo =
      parts.length > 1 ? { to: decodeURI(parts[1]), offset: 0 } : { to: 'post-title', offset: -100 }
    scroller.scrollTo(scrollTo.to, {
      duration: 200,
      delay: 50,
      smooth: true,
      offset: scrollTo.offset,
    })
  }, [])

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={title} description={description || excerpt} />
      <Box>
        <Box display="flex" flexDirection="row-reverse" justifyContent="center">
          <Hidden mdDown>
            <TOC
              toc={tableOfContents}
              style={{
                flex: '0 0 auto',
                alignSelf: 'flex-start',
                position: 'sticky',
                top: tocSideBar.stickyTop,
                width: tocSideBar.width,
                maxHeight: `calc(100vh - ${tocSideBar.stickyTop})`,
              }}
            />
          </Hidden>
          <Hidden lgUp>
            <TOCDrawer toc={tableOfContents} />
          </Hidden>
          <Box style={{ flex: '1 1 auto', maxWidth: '100%' }}>
            <Paper elevation={1}>
              <Box className={classes.container}>
                <BlogHeader blog={data.mdx} />
                <MDXProvider
                  components={{
                    h1: props => <Heading size="h1" {...props} />,
                    h2: props => <Heading size="h2" {...props} />,
                    h3: props => <Heading size="h3" {...props} />,
                    h4: props => <Heading size="h4" {...props} />,
                    h5: props => <Heading size="h5" {...props} />,
                    h6: props => <Heading size="h6" {...props} />,
                  }}
                >
                  <MDXRenderer>{body}</MDXRenderer>
                </MDXProvider>
                <Paper variant="outlined">
                  <Box className={classes.container}>
                    <Bio></Bio>
                  </Box>
                </Paper>
              </Box>
              <Divider></Divider>
              <Grid
                container
                justify="space-between"
                alignContent="stretch"
                spacing={2}
                className={classes.container}
              >
                {previous && <BlogNavButton type="prev" target={previous} />}
                {next && <BlogNavButton type="next" target={next} />}
              </Grid>
            </Paper>
            <Box mt={2}>
              <Card>
                <CardContent>
                  <Comment slug={fields.slug} />
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      fields {
        slug
        sub
      }
      frontmatter {
        title
        date
        description
        tags
        category
        draft
      }
      headings {
        depth
        value
      }
      tableOfContents(maxDepth: 3)
    }
  }
`
